import * as React from "react";
const PinIcon = (
    props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
  ) => (
  <svg
    fill={props.fill || '#000000'}
    height={props.height || '15px'}
    width={props.width || '15px'}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 9.6 9.6"
    enableBackground="new 0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M4.8 0C2.799 0 1.2 1.599 1.2 3.6c0 0.692 0.206 1.226 0.564 1.768l2.657 4.031c0.081 0.122 0.219 0.201 0.379 0.201s0.3 -0.081 0.379 -0.201l2.657 -4.031C8.194 4.826 8.4 4.292 8.4 3.6 8.4 1.599 6.801 0 4.8 0zm0 5.599c-1.104 0 -2.001 -0.896 -2.001 -2.002S3.696 1.594 4.8 1.594c1.104 0 2.001 0.896 2.001 2.002S5.904 5.599 4.8 5.599z" />
  </svg>
);
export default PinIcon;
