import styled from "styled-components";

interface StyledProps {
  disabled?: boolean;
  selected?: boolean;
  hidePopup?: boolean;
  hasNumberStyling?: boolean;
  storeContainerStyles?: boolean;
  hideIcon?: boolean;
  visibile?: boolean;
  disableClick?: boolean;
}

export const StyledSubmitNumberModal = styled.div`
  z-index: 1101;
  position: absolute;
  inset: 0;
  /* width: 100vw;
    height: 100vh; */
  background-color: rgba(0, 0, 0, 0.90);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledSubmitNumberBox = styled.div`
  position: absolute;
  /* min-width: 200px; */
  min-height: 200px;
  margin: 20px;
  border-radius: 10px;
  background-color: #f5faff;

  @media screen and (max-width: 800px) {
    margin-bottom: 300px;
  }
  @media screen and (max-width: 600px) {
    max-width: 90vw;
  }
`;
export const StyledSubmitNumberContent = styled.div`
  /* margin: 40px 30px 30px 30px; */
  margin: 15px 50px 50px 50px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    max-width: 100%;
    margin: 30px 10px 60px 10px;
  }
`;

export const StyledClosePopup = styled.div`
  z-index: 1001;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 0px;
  left: 0px;
  transform: translate(-50%, -50%);
  background-color: #f5faff;
  box-shadow: 0px 0px 30px rgb(0,0,0);
  cursor: pointer;
  color: var(--theme);
  font-weight: 500;

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  & .tooltiptext:hover {
    visibility: hidden;
    opacity: 0;
  }
  @media screen and (max-width: 650px) {
    left: 20px;
  }
`;

export const StyledPopupTitle = styled.h1`
  text-align: center;
  margin-bottom: 10px; // seperate for mobile
  min-width: 500px;
  @media screen and (max-width: 430px) {
    min-width: unset;
  }
`;
export const StyledPopupTextDescription = styled.p`
  text-align: center;
  margin: 0px;
`;
export const StyledPopupNumberInputBox = styled.div`
  position: relative;
  margin: 25px 0px 10px 0px;
  & > svg {
    position: absolute;
    border-radius: 50%;
    right: -7px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f5faff;
  }
  & > svg path {
    fill: var(--theme);
  }
`;
export const StyledPopupNumberInput = styled.input<StyledProps>`
  box-shadow: 0px 0px 15px rgb(177, 174, 174);
  color: #42494d;
  border: 1px solid var(--theme);
  border-radius: 50px;
  padding: ${(p) => p?.hasNumberStyling ? `10px 25px 10px 10px` : `10px 45px 10px 15px`};
  font-size: 18px;
  outline: none;
  text-align: center;
  width: ${(p) => p?.hasNumberStyling ? `300px` : `400px`};
  background-color: #f5faff;
  ::placeholder {
    color: #9e9e9e;
    text-align: center;
  }

  @media screen and (max-width: 430px) {
    width: unset;
    max-width: ${(p) => p?.hasNumberStyling ? `300px` : `400px`};
  }
`;
export const StyledPinWrapper = styled.div`
  background-color: var(--theme);
  position: absolute;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  right: -15px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 27px;
    height: 27px;
  }
  & > svg path {
    fill: #f5faff;
  }
`;

export const StyledPopupSubmitButtonsWrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: ${(p) => p?.visibile ? `visible` : `hidden`};
`;

export const StyledSmsButton = styled.span<StyledProps>`
  outline: none;
  border: unset;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline #000000;
  pointer-events: ${(p) => p?.disableClick ? `none` : `auto`};

  &:hover {
   text-decoration: underline #1e90ff;
   color: #1e90ff
  }
`;

export const StyledPopupSubmitButton = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 5px;

  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  border: unset;
  letter-spacing: 2px;
  min-width: 160px;
  min-height: 40px;
  white-space: normal;
  font-weight: 700;
  font-family: 'Rubik-Medium';
  text-align: center;
  padding: 0px 15px;
  margin: 20px 0 0 0;
  color: #fff;
  background-image: linear-gradient(180deg, #5ad066 0%, #28b33f 100%);
  border-radius: 33px;
  box-shadow: 0 2px 20px rgba(63,63,63,0.2);

  ${(p) =>
    !p?.disabled &&
    `
    :hover {
      background-image: linear-gradient(180deg, #21e065 0%, #1c892e 100%);
    }
  `}
  ${(p) =>
    p?.disabled &&
    `
    background: #9e9e9e;
    border-color: #9e9e9e;
    box-shadow: none;
    cursor: default;
  `}
`;
export const StyledButtonText = styled.p`
  /* font-size: 25px; */
`;
export const STyledButtonIcon = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p?.hideIcon && `visibility: hidden; position: absolute;`}
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  & > svg path {
    fill: #cfcece;
  }
`;

export const StyledOptionsPopup = styled.div<StyledProps>`
  /* box-sizing: border-box; */
  z-index: 1001;
  border-radius: 10px;
  background-color: #f5faff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
  position: absolute;
  right: 0%;
  min-width: 100%;
  max-width: 500px;
  min-height: 31px;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  visibility: ${(p) => p?.hidePopup ? `hidden` : `visible`};

  ${(p) => p?.storeContainerStyles && `
      @media screen and (max-width: 1800px) {
        top: 43px;
      }
  `}
`;

export const StyledOptionItem = styled.div<StyledProps>`
  min-width: 100%;
  padding: 0px 4px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  justify-content: right;
  cursor: default;
  font-family: heebo, sans-serif;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: right;
  line-height: 30px;
  font-size: 11px;
  color: #515151;

  ${(p) => p?.selected && 'background-color: #e9e9e9;'}

  &:hover {
    background-color: #e9e9e9;
  }
  &:hover > div > svg path {
    fill: var(--theme);
  }
`;

export const StyledResponse = styled.div`
  position: absolute;
  bottom: 15px;
  margin: 10px 0 0 0;
  font-size: 22px;
  font-weight: 500;
  color: #21e065;
`;
