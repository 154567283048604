const MobileIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 0.9 0.9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.225 0.188a0.075 0.075 0 0 1 0.075 -0.075h0.3a0.075 0.075 0 0 1 0.075 0.075v0.525a0.075 0.075 0 0 1 -0.075 0.075H0.3a0.075 0.075 0 0 1 -0.075 -0.075V0.188zm0.375 0H0.3v0.525h0.3V0.188z"
      fill={props.fill || '#0D0D0D'}
    />
    <path
      d="M0.487 0.637a0.037 0.037 0 1 1 -0.075 0 0.037 0.037 0 0 1 0.075 0z"
      fill={props.fill || '#0D0D0D'}
    />
  </svg>
);
export default MobileIcon;

