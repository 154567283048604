import styled from "styled-components";

export const StyledMainContent = styled.div`
  min-height: 100vh;
  display: flex;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--whiteBg);
`;

export const StyledLoaderWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1101;
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.90);
  pointer-events: none;
`;

export const StyledCloseWindow = styled.div`
  z-index: 1001;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50px;
  left: 50px;
  transform: translate(-50%, -50%);
  background-color: #f5faff;
  box-shadow: 0px 0px 50px #00000080;
  cursor: pointer;
  color: var(--theme);
  font-weight: 500;

  @media screen and (max-width: 800px) {
    top: 40px;
    left: 40px;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  & .tooltiptext:hover {
    visibility: hidden;
    opacity: 0;
  }
`;

export const StyledTooltip = styled.span`
  visibility: hidden;
  opacity: 0;
  user-select: none;
  cursor: default;
  width: 100px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: calc(50% - 15px);
  left: calc(125% + 55px);
  margin-left: -60px;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
`;
