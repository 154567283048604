import React from "react";

import WazeIcon from "assets/svg-components/WazeIcon";
import PhoneIcon from "assets/svg-components/PhoneIcon";
import PinIcon from "assets/svg-components/PinIcon";
import QuestionMarkIcon from "assets/svg-components/QuestionMarkIcon";
import CityIcon from "assets/svg-components/CityIcon";
import WhatsAppIcon from "assets/svg-components/WhatsAppIcon";

import {
  StyledStoreItem,
  StyledItemDistanceBox,
  StyledItemInfoBox,
  StyledItemInfoPlaceHolder,
  StyledButtonsBox,
  StyledItemSelectButton,
  StyledIconWrapper,
  StyledItemDistanceText,
  StyledItemName,
  StyledItemInfoRow,
  StyledButtonText,
  StyledButtonIcon,
} from "./styles";

interface Props {
  isMobileDevice: boolean;
  item?: any;
  isItemPlaceholder?: boolean;
  markerFocused: number | null;
  setMarkerFocused: React.Dispatch<React.SetStateAction<number | null>>;
  openPopup: (selectedItem: any, isWhatsApp?: boolean) => void;
}

const StoreListItem: React.FC<Props> = ({
  isMobileDevice,
  item,
  isItemPlaceholder,
  markerFocused,
  setMarkerFocused,
  openPopup,
}) => {
  const handleShowOnMap = () => {
    if (isMobileDevice || !item?.id) {
      return;
    }
    setMarkerFocused((prev) => (prev === item.id ? null : item.id));
  };

  const handleWhatsApp = (whatsapp: string) => {
    console.log("123 handleWhatsApp param: ", whatsapp);
    const dummy = '972527430632';
    window.open(
      `https://api.whatsapp.com/send/?phone=${dummy}&text&type=phone_number&app_absent=0`,
      "_blank"
    );
  };

  return (
    <StyledStoreItem
      onClick={handleShowOnMap}
      markerFocused={item?.id === markerFocused}
    >
      <StyledItemDistanceBox>
        <StyledIconWrapper>
          <PinIcon fill="var(--theme)" width={"35px"} height={"35px"} />
        </StyledIconWrapper>
        <StyledItemDistanceText style={{ fontSize: "25px" }}>
          {!isItemPlaceholder && (
            <b>
              {item.distance < 100
                ? item.distance.toPrecision(2)
                : Math.floor(item.distance)}
            </b>
          )}
          {isItemPlaceholder && <b>0.0</b>}
        </StyledItemDistanceText>
        <StyledItemDistanceText>ק"מ ממך</StyledItemDistanceText>
        <StyledItemDistanceText
          // onClick={handleShowOnMap}
          style={{ color: "var(--theme)", marginTop: "5px", cursor: "pointer" }}
        >
          הצג על המפה
        </StyledItemDistanceText>
      </StyledItemDistanceBox>
      {isItemPlaceholder && (
        <StyledItemInfoBox style={{ minWidth: "420px" }}>
          <StyledItemInfoPlaceHolder />
          <StyledItemInfoPlaceHolder />
          <StyledItemInfoPlaceHolder />
          <StyledItemInfoPlaceHolder />
        </StyledItemInfoBox>
      )}
      {!isItemPlaceholder && (
        <StyledItemInfoBox>
          <StyledItemName>{item.name}</StyledItemName>
          <StyledItemInfoRow>
            {item.city || `עיר`}
            <StyledIconWrapper>
              <CityIcon />
            </StyledIconWrapper>
          </StyledItemInfoRow>
          <StyledItemInfoRow>
            {item.address || `כתובת`}
            <StyledIconWrapper>
              <PinIcon />
            </StyledIconWrapper>
          </StyledItemInfoRow>
          <StyledItemInfoRow>
            {!!item?.whatsApp && false && (
              <StyledIconWrapper
                btnStyles
                onClick={() => handleWhatsApp(item.whatsApp)}
              >
                <WhatsAppIcon fill={"#5ad066"} />
              </StyledIconWrapper>
            )}
            {item.phone || `טלפון`}
            <StyledIconWrapper>
              <PhoneIcon />
            </StyledIconWrapper>
          </StyledItemInfoRow>
          <StyledItemInfoRow>
            מומלץ לבדוק את זמינות המוצר לפני מיקום ההגעה
            <StyledIconWrapper>
              <QuestionMarkIcon />
            </StyledIconWrapper>
          </StyledItemInfoRow>
        </StyledItemInfoBox>
      )}
      <StyledButtonsBox>
        <StyledItemSelectButton onClick={() => openPopup(item)}>
          <StyledButtonText> קח אותי למשווק</StyledButtonText>
          <StyledButtonIcon>
            <WazeIcon fill={"#ffffff"} />
          </StyledButtonIcon>
        </StyledItemSelectButton>
        {/* <StyledItemSelectButton onClick={() => openPopup(item, true)}>
          <StyledButtonText>WhatsApp</StyledButtonText>
          <StyledButtonIcon>
            <WhatsAppIcon />
          </StyledButtonIcon>
        </StyledItemSelectButton> */}
      </StyledButtonsBox>
    </StyledStoreItem>
  );
};

export default StoreListItem;
