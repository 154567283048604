import * as React from "react";
const QuestionMarkIcon = (
    props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
  ) => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 0.3 0.3"
    xmlns="http://www.w3.org/2000/svg"
    fill={props.fill || '#9e9e9e'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.141 0.019a0.122 0.122 0 1 0 0 0.244 0.122 0.122 0 0 0 0 -0.244zm0 0.225a0.103 0.103 0 1 1 0 -0.206 0.103 0.103 0 0 1 0 0.206zm0.029 -0.158a0.035 0.035 0 0 0 -0.011 -0.008A0.042 0.042 0 0 0 0.141 0.075a0.041 0.041 0 0 0 -0.017 0.003c-0.004 0.002 -0.008 0.005 -0.012 0.008a0.035 0.035 0 0 0 -0.007 0.012 0.056 0.056 0 0 0 -0.003 0.013h0.023a0.016 0.016 0 0 1 0.009 -0.014 0.013 0.013 0 0 1 0.006 -0.001 0.016 0.016 0 0 1 0.005 0 0.012 0.012 0 0 1 0.004 0.003 0.011 0.011 0 0 1 0.003 0.004 0.014 0.014 0 0 1 0.001 0.006c0 0.003 -0.001 0.006 -0.002 0.009a0.045 0.045 0 0 1 -0.005 0.009l-0.007 0.008c-0.002 0.002 -0.004 0.005 -0.007 0.008a0.044 0.044 0 0 0 -0.005 0.008 0.022 0.022 0 0 0 -0.002 0.009v0.012H0.15v-0.009a0.018 0.018 0 0 1 0.002 -0.008 0.058 0.058 0 0 1 0.005 -0.008l0.007 -0.008a0.08 0.08 0 0 0 0.007 -0.009 0.049 0.049 0 0 0 0.005 -0.01 0.036 0.036 0 0 0 0.002 -0.012 0.041 0.041 0 0 0 -0.002 -0.013 0.028 0.028 0 0 0 -0.007 -0.01zM0.128 0.184h0.022V0.206H0.128V0.184z"
    />
  </svg>
);
export default QuestionMarkIcon;
