import styled from "styled-components";

interface StyledProps {
  markerFocused?: boolean;
  dropdownVisible?: boolean;
  btnStyles?: boolean;
  logoImg?: string;
}

export const StyledNearestStoreContainer = styled.div`
  position: relative;
  flex: 1;
  z-index: 10;
  margin: 1px 0px;
  box-sizing: border-box;
`;
export const StyledStoresBox = styled.div`
  position: relative;
  /* max-height: calc(100vh - 200px); */
  min-height: calc(100vh - 2px);
  max-height: calc(100vh - 2px);
  padding: 10px;
  margin: 0 10px 0 0;
  overflow-y: scroll;
  background-color: var(--whiteBg);

  @media screen and (max-width: 800px) {
    padding: 30px 5px 100px 5px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #3e434f;
    border-radius: 4px;
  }
`;

export const StyledBottomButtonBox = styled.div`
  position: relative;
  height: 200px;
  padding: 10px;
  box-shadow: 0px 20px 50px #00000080;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledStoresHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  @media (min-width: 800px) and (max-width: 1050px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`;
export const StyledHeaderLogo = styled.div<StyledProps>`
  ${(p) => p?.logoImg && `background-image: url(${p.logoImg});`}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  min-width: 70px;
  min-height: 70px;
  margin: 8px 0 0 20px;
`;
export const StyledStoresBoxTitle = styled.h1`
  text-align: right;
  font-size: 2.5em;
  margin: 0px 0px 8px 0px;
`;

export const StyledStoresBoxTextDescription = styled.p`
  text-align: right;
`;
export const StyledStoresListWrapper = styled.div`
  z-index: 100;
`;

export const StyledCountBox = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0 0 0;
`;
export const StyledCountText = styled.p`
  text-align: right;
  margin: 0 5px 0 0;
`;

export const StyledInputsWrapper = styled.div`
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1300px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const StyledCategoryWrapper = styled.div`
  position: relative;
  margin: 25px 0px 10px 0px;
  @media screen and (max-width: 1300px) {
    margin: 10px 0px;
  }
`;

export const StyledPopupAddressInputBox = styled.div`
  position: relative;
  margin: 25px 0px 10px 0px;
  & > svg {
    position: absolute;
    border-radius: 50%;
    right: -7px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f5faff;
  }
  & > svg path {
    fill: var(--theme);
  }
  @media screen and (max-width: 1800px) {
    width: calc(100% - 250px);
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 1300px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
export const StyledPopupAddressInput = styled.input<StyledProps>`
  box-shadow: 0px 0px 15px rgb(177, 174, 174);
  color: #42494d;
  border: 1px solid var(--theme);
  border-radius: 50px;
  padding: 10px 45px 10px 15px;
  font-size: 18px;
  outline: none;
  text-align: center;
  width: 650px;
  background-color: #f5faff;
  ::placeholder {
    color: #9e9e9e;
    text-align: center;
  }

  @media screen and (max-width: 1800px) {
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 600px;
  }
`;

export const StyledArrowWrapper = styled.div<StyledProps>`
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  right: 5px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 15px;
    height: 15px;
  }
  transform: translate(-50%, -50%)
    ${(p) => (p?.dropdownVisible ? `rotate(180deg)` : `rotate(0deg)`)};
`;
export const StyledCategoryButton = styled.div`
  cursor: pointer;
  box-shadow: 0px 0px 15px rgb(177, 174, 174);
  color: #42494d;
  border: 1px solid var(--theme);
  border-radius: 50px;
  padding: 10px 45px 10px 15px;
  font-size: 18px;
  outline: none;
  text-align: center;
  min-width: 150px;
  max-width: 200px;
  background-color: #f5faff;
`;

/////
///////////////////////// BELOW STYLES FOR 'StoreListItem':
/////
export const StyledItemDistanceBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 3px;
  justify-content: center;
  align-items: center;

  background-color: var(--greyBg);
  border-radius: 10px;

  padding: 10px 15px;

  @media (min-width: 800px) and (max-width: 1100px) {
    padding: 10px 5px;
    font-size: 15px;
    min-width: 110px;
  }
  @media screen and (max-width: 550px) {
    padding: 10px 5px;
    font-size: 15px;
    min-width: 110px;
  }
`;

export const StyledStoreItem = styled.div<StyledProps>`
  position: relative;
  margin: 10px 0px 40px 0px;
  padding: 20px 10px 30px 10px;
  border-radius: 10px;
  border: 2px solid var(--greyBg);
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  /* align-items: center; */
  /* min-height: 200px; */

  ${(p) =>
    p?.markerFocused &&
    `
      background-color: var(--greyBg);
      border-color: var(--greyFont);
      & ${StyledItemDistanceBox} {
        background-color: var(--whiteBg);
      }
    `}
`;

export const StyledItemInfoPlaceHolder = styled.div`
  width: 80%;
  height: 20px;
  border-radius: 20px;
  background-color: #b8b8b8;
`;
export const StyledItemInfoBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  min-height: 100%;
  padding: 0px 0px 10px 0px;
`;
export const StyledButtonsBox = styled.div`
  z-index: 101;
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, +50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledItemSelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 5px;

  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  border: unset;
  letter-spacing: 2px;
  min-width: 160px;
  min-height: 40px;
  white-space: normal;
  font-weight: 700;
  font-family: "Rubik-Medium";
  text-align: center;
  padding: 0px 15px;
  margin: 0px;
  color: #fff;
  background-image: linear-gradient(180deg, #5ad066 0%, #28b33f 100%);
  border-radius: 33px;
  box-shadow: 0 2px 20px rgba(63, 63, 63, 0.2);
  :hover {
    background-image: linear-gradient(180deg, #21e065 0%, #1c892e 100%);
  }
`;
export const StyledIconWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  ${(p) =>
    p?.btnStyles &&
    `
      cursor: pointer;
      width: 22px;
      height: 22px;
      padding: 0 0 2px 0;
      margin: 0 8px;
      border-radius: 50%;
    `}
`;
export const StyledItemDistanceText = styled.div`
  text-align: center;
`;
export const StyledItemName = styled.div`
  font-weight: 700;
  font-size: 20px;
`;
export const StyledItemInfoRow = styled.div`
  display: flex;
  align-items: center;
  color: #9e9e9e;
  max-width: 370px;
  text-align: right;
`;

export const StyledButtonText = styled.p`
  /* font-size: 25px; */
`;
export const StyledButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
