import * as React from "react";
const WazeIcon = (
    props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
  ) => (
    <svg
      fill={props.fill || "#000000"}
      width="30px"
      height="30px"
      viewBox="0 0 0.525 0.525"
      role="img"
      focusable="false"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.485 0.213a0.17 0.17 0 0 0 -0.03 -0.072 0.196 0.196 0 0 0 -0.071 -0.06 0.207 0.207 0 0 0 -0.093 -0.022c-0.009 0 -0.018 0.001 -0.027 0.002A0.215 0.215 0 0 0 0.162 0.105c-0.033 0.026 -0.054 0.059 -0.061 0.097 -0.002 0.011 -0.003 0.023 -0.004 0.035 -0.001 0.019 -0.003 0.038 -0.009 0.05 -0.004 0.009 -0.01 0.014 -0.026 0.014a0.024 0.024 0 0 0 -0.019 0.038c0.024 0.033 0.056 0.052 0.09 0.064a0.047 0.047 0 0 0 0.044 0.061c0.025 0 0.046 -0.02 0.047 -0.045 0.011 0.001 0.063 0.001 0.069 0.001a0.047 0.047 0 1 0 0.09 -0.019c0.018 -0.009 0.036 -0.021 0.051 -0.035 0.021 -0.02 0.037 -0.044 0.045 -0.069a0.173 0.173 0 0 0 0.007 -0.084m-0.308 0.22a0.015 0.015 0 1 1 0 -0.031 0.015 0.015 0 0 1 0 0.031m0.162 0a0.015 0.015 0 1 1 0 -0.031 0.015 0.015 0 0 1 0 0.031m0.117 -0.144c-0.013 0.041 -0.049 0.075 -0.087 0.092a0.047 0.047 0 0 0 -0.071 0.015 1.813 1.813 0 0 1 -0.08 -0.001 0.047 0.047 0 0 0 -0.072 -0.013c-0.032 -0.01 -0.062 -0.028 -0.085 -0.058 0.067 0 0.054 -0.075 0.062 -0.119 0.013 -0.067 0.08 -0.113 0.145 -0.121a0.178 0.178 0 0 1 0.024 -0.002c0.106 0 0.201 0.095 0.164 0.206M0.291 0.322c-0.038 0 -0.073 -0.025 -0.079 -0.058a0.012 0.012 0 0 1 0.023 -0.004c0.004 0.019 0.027 0.04 0.058 0.039 0.032 -0.001 0.053 -0.02 0.058 -0.039a0.012 0.012 0 0 1 0.023 0.005c-0.004 0.015 -0.013 0.029 -0.028 0.04a0.093 0.093 0 0 1 -0.055 0.017m0.076 -0.121a0.023 0.023 0 1 1 -0.045 0 0.023 0.023 0 0 1 0.045 0m-0.106 0a0.023 0.023 0 1 1 -0.045 0 0.023 0.023 0 0 1 0.045 0" />
    </svg>
  );
export default WazeIcon;
