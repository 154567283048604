// robotics = "רובוטיקה וציפוי",
// welding = "ציוד ריתוך",
// painting = "ציוד צביעה",
// construction = "ציוד בניה",
// tools = "כלי עבודה",
// electricTools = "כלי עבודה חשמליים",

export const hebList: HebCategoryType[] = [
  "כלי עבודה חשמליים",
  "כלי עבודה",
  "ציוד בניה",
  "ציוד צביעה",
  "ציוד ריתוך",
  "מסירת כלי תקול",
  "רובוטיקה וציפוי",
];

export type HebCategoryType =
  | "רובוטיקה וציפוי"
  | "מסירת כלי תקול"
  | "ציוד ריתוך"
  | "ציוד צביעה"
  | "ציוד בניה"
  | "כלי עבודה"
  | "כלי עבודה חשמליים"
  | "";

export const engList: CategoryType[] = [
  "electricTools",
  "repair",
  "tools",
  "construction",
  "painting",
  "welding",
  "robotics",
];

export type CategoryType =
  | "robotics"
  | "welding"
  | "painting"
  | "construction"
  | "tools"
  | "repair"
  | "electricTools"
  | "";

export enum CategoryEnum {
  "רובוטיקה וציפוי" = "welding",
  "ציוד ריתוך" = "welding",
  "ציוד צביעה" = "painting",
  "ציוד בניה" = "construction",
  "כלי עבודה" = "tools",
  "מסירת כלי תקול" = "repair",
  "כלי עבודה חשמליים" = "electricTools",
}
