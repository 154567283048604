import * as React from "react";
const PhoneIcon = (
    props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
  ) => (
  <svg
    fill={props.fill || '#000000'}
    width="15px"
    height="15px"
    viewBox="0 0 0.45 0.45"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.259 0.26c-0.044 0.045 -0.096 0.087 -0.116 0.067 -0.029 -0.029 -0.047 -0.055 -0.112 -0.003s-0.015 0.086 0.013 0.114c0.033 0.033 0.154 0.002 0.275 -0.118s0.151 -0.242 0.118 -0.275c-0.028 -0.028 -0.063 -0.078 -0.114 -0.013s-0.026 0.082 0.003 0.111c0.02 0.021 -0.022 0.072 -0.067 0.117z" />
  </svg>
);
export default PhoneIcon;
