import styled from "styled-components";

interface StyledProps {
  bgImg?: string;
  bgImgHover?: string;
}

export const StyledMapsContainer = styled.div`
  position: relative;
  flex: 1;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const StyledIframe = styled.iframe`
  border: 0;
  width: 100%;
  height: calc(
    100vh + 70px
  ); // hide Google Maps Black header with negative margin!!!
  margin-top: -70px; // hide Google Maps Black header with negative margin!!!
`;

export const StyledMarker = styled.div`
  width: 10px;
  height: 10px;
  background: red;
`;

export const StyledHintBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;

  z-index: 1;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02);
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px 10px 0;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
`;
export const StyledHintClose = styled.span<StyledProps>`
  ${(p) => p?.bgImg && `background-image: url(${p.bgImg});`}
  background-size: cover;
  height: 24px;
  width: 24px;
  margin: 0 15px 0 10px;
  cursor: pointer;
  &:hover {
    ${(p) => p?.bgImgHover && `background-image: url(${p.bgImgHover});`}
  }
`;
export const StyledHintText = styled.p`
  display: flex;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    left: 40px;
    top: 10%;
    border-left: 1px solid #dadce0;
    height: 80%;
  }
`;
export const StyledHintImgWrapper = styled.span`
  cursor: pointer;
  position: relative;
  display: flex;
  direction: rtl;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;
export const StyledHintImg = styled.img`
  width: 15px;
  height: 15px;
`;