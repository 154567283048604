import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import LoaderSpinner from "atoms/LoaderSpinner/LoaderSpinner";
import WazeIcon from "assets/svg-components/WazeIcon";
import MobileIcon from "assets/svg-components/MobileIcon";
import { StyledTooltip } from "components/MainContent/styles";

import {
  StyledSubmitNumberModal,
  StyledSubmitNumberBox,
  StyledSubmitNumberContent,
  StyledClosePopup,
  StyledPopupTitle,
  StyledPopupTextDescription,
  StyledPopupNumberInputBox,
  StyledPopupNumberInput,
  StyledPopupSubmitButtonsWrapper,
  StyledSmsButton,
  StyledPopupSubmitButton,
  StyledButtonText,
  STyledButtonIcon,
  StyledResponse,
} from "./styles";

interface Props {
  modalType: "phone" | "whatsapp";
  selectedStoreId: number | null;
  productName: string;
  closePopup: () => void;
}

const SubmitNumberModal: React.FC<Props> = ({
  modalType,
  selectedStoreId,
  productName,
  closePopup,
}) => {
  const [successRes, setSuccessRes] = useState<string | null>(null);
  const [loadingRes, setLoadingRes] = useState(false);
  const [input, setInput] = useState("");
  const popupRef = useRef<HTMLDivElement>(null);

  const submitInput = useCallback(
    (isSms: boolean) => {
      if (input.length < 8 || selectedStoreId === null) {
        alert("invalid input");
        return;
      }
      setLoadingRes(true);
      const uploadNumber = async () => {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}store/sms`,
            {
              storeId: selectedStoreId,
              phoneNumber: input,
              productName,
              isSms,
            }
          );
          if (res?.status === 201) {
            setSuccessRes("success");
          }
          setInput("");
          setLoadingRes(false);
        } catch (err) {
          setSuccessRes("error");
          setLoadingRes(false);
          console.log("uploadNumber error: ", err);
        }
      };
      uploadNumber();
    },
    [input, selectedStoreId, productName]
  );

  useEffect(() => {
    let handler: ReturnType<typeof setTimeout>;
    if (successRes !== null) {
      handler = setTimeout(() => {
        setSuccessRes(null);
      }, 5000);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [successRes]);

  // const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter") {
  //     submitInput();
  //   }
  // };

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.key === "Escape") closePopup();
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [closePopup]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [closePopup]);

  return (
    <StyledSubmitNumberModal>
      <StyledSubmitNumberBox ref={popupRef}>
        <StyledSubmitNumberContent>
          <StyledClosePopup onClick={closePopup}>
            X<StyledTooltip className="tooltiptext">Close</StyledTooltip>
          </StyledClosePopup>
          <StyledPopupTitle>קבלו הודעה שתנווט אתכם ישר למשווק</StyledPopupTitle>
          <StyledPopupTextDescription>
            לא יעשה כל שימוש במספר הטלפון מלבד שליחת הודעה זו
          </StyledPopupTextDescription>
          <StyledPopupNumberInputBox>
            <StyledPopupNumberInput
              hasNumberStyling
              placeholder={
                modalType === "phone"
                  ? `הזינו מספר טלפון נייד`
                  : `Enter your Whatsapp number`
              }
              dir="rtl"
              maxLength={20}
              type={"number"}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              spellCheck={false}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              // onKeyDown={(e) => handleKeyPress(e)}
            />
            <MobileIcon />
          </StyledPopupNumberInputBox>
          {loadingRes && <LoaderSpinner size="xs" />}
          <StyledPopupSubmitButtonsWrapper visibile={!loadingRes}>
            <StyledPopupSubmitButton
              onClick={() => submitInput(false)}
              disabled={input.length < 8 || loadingRes}
            >
              <StyledButtonText>קח אותי למשווק</StyledButtonText>
              <STyledButtonIcon hideIcon={loadingRes}>
                <WazeIcon fill={"#ffffff"} />
              </STyledButtonIcon>
            </StyledPopupSubmitButton>
            <StyledPopupTextDescription
              style={{ marginTop: "10px", direction: "rtl" }}
            >
              אם אין לך חשבון ווטסאפ{` `}
              <StyledSmsButton
                onClick={() => submitInput(true)}
                disableClick={input.length < 8 || loadingRes}
              >
                לחץ כאן
              </StyledSmsButton>
              {` `}
              בכדי לקבל SMS 
            </StyledPopupTextDescription>
          </StyledPopupSubmitButtonsWrapper>
          {successRes === "success" && (
            <StyledResponse>הודעה נשלחה למספר שהוזן</StyledResponse>
          )}
        </StyledSubmitNumberContent>
      </StyledSubmitNumberBox>
    </StyledSubmitNumberModal>
  );
};

export default SubmitNumberModal;
