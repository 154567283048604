import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SetValue, Status } from "use-places-autocomplete";

import detectMobile from "utils/detectMobile";
import StoreListItem from "./StoreListItem";

import ArrowIcon from "assets/svg-components/ArrowIcon";
import PinIcon from "assets/svg-components/PinIcon";
import LogoTransparent from "assets/imgs/LogoTransparent.png";

import { CategoryType, HebCategoryType, hebList } from "config";

import {
  StyledNearestStoreContainer,
  StyledStoresBox,
  StyledStoresHeader,
  StyledHeaderLogo,
  StyledStoresBoxTitle,
  StyledStoresBoxTextDescription,
  StyledStoresListWrapper,
  StyledCountBox,
  StyledCountText,
  StyledInputsWrapper,
  StyledCategoryWrapper,
  StyledPopupAddressInputBox,
  StyledPopupAddressInput,
  StyledArrowWrapper,
  StyledCategoryButton,
} from "./styles";

import {
  // StyledPopupNumberInputBox,
  // StyledPopupNumberInput,
  StyledPinWrapper,
  StyledIconWrapper,
  StyledOptionsPopup,
  StyledOptionItem,
} from "components/SubmitModal/styles";

interface Props {
  parsed: any; // queryString.ParsedQuery<string>;
  readyToShow: boolean;
  openPopup: (selectedItem: any, isWhatsApp?: boolean) => void;
  markerFocused: number | null;
  setMarkerFocused: React.Dispatch<React.SetStateAction<number | null>>;
  storesData: any;
  fetchStoreData: (
    lattitude?: number,
    longitude?: number,
    productCategory?: CategoryType
  ) => Promise<void>;
  ready: boolean;
  value: string;
  setValue: SetValue;
  status: Status;
  data: google.maps.places.AutocompletePrediction[];
  selectedProductType: HebCategoryType;
  handleSelect: (address: string) => Promise<void>;
}

const isMobileDevice = detectMobile();

const NearestStoreContainer: React.FC<Props> = ({
  parsed,
  readyToShow,
  openPopup,
  markerFocused,
  setMarkerFocused,
  storesData,
  fetchStoreData,
  ready,
  value,
  setValue,
  status,
  data,
  selectedProductType,
  handleSelect,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedListIndex, setSelectedListIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState("");

  useEffect(() => {
    if (markerFocused !== null) {
      const element = document.querySelector(
        `div[data-selected-index='${markerFocused}']`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [markerFocused]);

  const detectArrows = useCallback(
    (e: any) => {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          e.stopPropagation();
          setSelectedListIndex((prevState) => prevState + 1);
          break;
        case "ArrowUp":
          e.preventDefault();
          e.stopPropagation();
          setSelectedListIndex((prevState) => prevState - 1);
          break;
        case "Enter":
          if (e.key === "Enter") {
            if (data.length > 0) {
              handleSelect(selectedAddress);
            } else {
              fetchStoreData();
            }
          }
          break;
        default:
        // console.log('detectArrows default:', e.key);
      }
    },
    [handleSelect, selectedAddress, data, fetchStoreData]
  );

  const onTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedListIndex(0);
    setValue(e.target?.value || "");
  };

  useEffect(() => {
    if (data.length !== 0) {
      if (selectedListIndex < 0) {
        setSelectedListIndex(data.length - 1);
      } else if (selectedListIndex > data.length - 1) {
        setSelectedListIndex(0);
      } else {
        const isMatch = data.find(
          (item) => item?.description?.toLowerCase() === value?.toLowerCase()
        );
        setSelectedAddress(
          isMatch?.description
            ? isMatch.description
            : data[selectedListIndex]?.description
        );
      }
    } else {
      setSelectedAddress("");
      setSelectedListIndex(0);
    }
  }, [value, data, selectedListIndex]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isInputFocused &&
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsInputFocused(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isInputFocused]);

  const showDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const selectCategory = (name: HebCategoryType) => {
    setSearchParams({ product: parsed?.product ?? 'אתר אינטרנט', category: name });
  };

  return (
    <StyledNearestStoreContainer>
      <StyledStoresBox>
        <StyledStoresHeader>
          <div>
            <StyledStoresBoxTitle>איתור המוצר באזור שלך</StyledStoresBoxTitle>
            <StyledStoresBoxTextDescription>
              לפניכם רשימת משווקים עם המוצר שחיפשתם, בחר את המשווק המועדף עליך
            </StyledStoresBoxTextDescription>
          </div>
          <StyledHeaderLogo logoImg={LogoTransparent} />
        </StyledStoresHeader>
        <StyledInputsWrapper>
          <StyledCategoryWrapper onClick={showDropdown}>
            <StyledCategoryButton>
              {selectedProductType || `בחר קטגוריה`}
            </StyledCategoryButton>
            <StyledArrowWrapper dropdownVisible={dropdownVisible}>
              <ArrowIcon />
            </StyledArrowWrapper>
            <StyledOptionsPopup hidePopup={!dropdownVisible}>
              {hebList.map((name: HebCategoryType, index) => {
                if (index === hebList?.length - 1) {
                  return (
                    <span
                      key={`${name}_${index}`}
                      style={{ display: "none" }}
                    />
                  );
                }
                return (
                  <StyledOptionItem
                    key={`${name}_${index}`}
                    onClick={() => selectCategory(name)}
                    selected={selectedProductType === name}
                  >
                    {name}
                  </StyledOptionItem>
                );
              })}
            </StyledOptionsPopup>
          </StyledCategoryWrapper>
          <StyledPopupAddressInputBox>
            <StyledPopupAddressInput
              ref={inputRef}
              placeholder={`הזינו כתובת ובחרו מהרשימה`}
              dir="rtl"
              spellCheck={false}
              disabled={!ready}
              value={value}
              onFocus={() => setIsInputFocused(true)}
              onChange={onTextChanged}
              onKeyDown={detectArrows}
            />
            <StyledPinWrapper>
              <PinIcon />
            </StyledPinWrapper>
            {status === "OK" && (
              <StyledOptionsPopup
                storeContainerStyles
                ref={popupRef}
                hidePopup={!isInputFocused}
              >
                {data.map(({ place_id, description }) => {
                  return (
                    <StyledOptionItem
                      key={place_id}
                      onClick={() => handleSelect(description)}
                      selected={selectedAddress === description}
                    >
                      {description}
                      <StyledIconWrapper>
                        <PinIcon />
                      </StyledIconWrapper>
                    </StyledOptionItem>
                  );
                })}
              </StyledOptionsPopup>
            )}
          </StyledPopupAddressInputBox>
        </StyledInputsWrapper>
        <StyledStoresListWrapper>
          <StyledCountBox>
            <StyledCountText>משווקים נמצאים באזור שלך</StyledCountText>
            <b>{storesData?.length}</b>
          </StyledCountBox>
          {!selectedProductType && storesData?.length === 0 && (
            <StyledCountBox>
              בחר קטגוריה והמערכת תאתר את המשווק הקרוב אליך
            </StyledCountBox>
          )}
          {!readyToShow &&
            storesData === null &&
            [0, 1, 2, 3, 4].map((item: any) => {
              return (
                <StoreListItem
                  isItemPlaceholder
                  key={item}
                  isMobileDevice={isMobileDevice}
                  markerFocused={markerFocused}
                  setMarkerFocused={setMarkerFocused}
                  openPopup={openPopup}
                />
              );
            })}
          {readyToShow &&
            storesData?.length > 0 &&
            storesData.map((item: any) => {
              if (!item?.id) {
                // eslint-disable-next-line array-callback-return
                return;
              }
              return (
                <div key={item.id} data-selected-index={item.id}>
                  <StoreListItem
                    isMobileDevice={isMobileDevice}
                    item={item}
                    markerFocused={markerFocused}
                    setMarkerFocused={setMarkerFocused}
                    openPopup={openPopup}
                  />
                </div>
              );
            })}
        </StyledStoresListWrapper>
      </StyledStoresBox>
    </StyledNearestStoreContainer>
  );
};

export default NearestStoreContainer;
