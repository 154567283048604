import React, { useCallback, useEffect, useRef, useState } from "react";

import { SetValue, Status } from "use-places-autocomplete";


import { StyledTooltip } from "components/MainContent/styles";

import PinIcon from "assets/svg-components/PinIcon";

import {
  StyledSubmitNumberModal,
  StyledSubmitNumberBox,
  StyledSubmitNumberContent,
  StyledClosePopup,
  StyledPopupTitle,
  StyledPopupTextDescription,
  StyledPopupNumberInputBox,
  StyledPopupNumberInput,
  StyledPinWrapper,
  // StyledPopupSubmitButton,
  // StyledButtonText,
  StyledIconWrapper,
  StyledOptionsPopup,
  StyledOptionItem,
} from "./styles";


interface Props {
  // setAskLocation?: React.Dispatch<React.SetStateAction<boolean>>;
  userLocation?: {
    city: string;
    lat: number;
    lng: number;
  };
  setUserLocation: React.Dispatch<
    React.SetStateAction<{
      city: string;
      lat: number;
      lng: number;
    }>
  >;
  getGeoLocatio: () => void;
  closePopup: () => void;
  fetchStoreData: (lat?: number, lng?: number) => Promise<void>;
  ready: boolean;
  value: string;
  setValue: SetValue;
  status: Status;
  data: google.maps.places.AutocompletePrediction[];
  handleSelect:  (address: string) => Promise<void>;
}

const AddressModal: React.FC<Props> = ({
  // setAskLocation,
  userLocation = {
    city: "Israel",
    lat: 31.4117,
    lng: 35.0818,
  },
  setUserLocation,
  getGeoLocatio,
  fetchStoreData,
  closePopup,
  ready,
  value,
  setValue,
  status,
  data,
  handleSelect,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  // const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedListIndex, setSelectedListIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState("");

  useEffect(() => {
    getGeoLocatio();
  }, [getGeoLocatio])
  
  const detectArrows = useCallback(
    (e: any) => {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          e.stopPropagation();
          setSelectedListIndex((prevState) => prevState + 1);
          break;
        case "ArrowUp":
          e.preventDefault();
          e.stopPropagation();
          setSelectedListIndex((prevState) => prevState - 1);
          break;
        case "Enter":
          if (e.key === "Enter") {
            if (data.length > 0) {
              handleSelect(selectedAddress);
            } else {
              fetchStoreData();
            }
          }
          break;
        default:
        // console.log('detectArrows default:', e.key);
      }
    },
    [handleSelect, selectedAddress, data, fetchStoreData]
  );

  const onTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedListIndex(0);
    setValue(e.target?.value || "");
  };

  useEffect(() => {
    if (data.length !== 0) {
      if (selectedListIndex < 0) {
        setSelectedListIndex(data.length - 1);
      } else if (selectedListIndex > data.length - 1) {
        setSelectedListIndex(0);
      } else {
        const isMatch = data.find(
          (item) => item?.description?.toLowerCase() === value?.toLowerCase()
        );
        setSelectedAddress(
          isMatch?.description
            ? isMatch.description
            : data[selectedListIndex]?.description
        );
      }
    } else {
      setSelectedAddress("");
      setSelectedListIndex(0);
    }
  }, [value, data, selectedListIndex]);

  const handleClose = () => {
    fetchStoreData();
    closePopup();
  };

  return (
    <StyledSubmitNumberModal>
      <StyledSubmitNumberBox>
        <StyledSubmitNumberContent>
          <StyledClosePopup onClick={handleClose}>
            X<StyledTooltip className="tooltiptext">Close</StyledTooltip>
          </StyledClosePopup>
          <StyledPopupTitle>לרכישת המוצר בקרבת מקום</StyledPopupTitle>
          <StyledPopupTextDescription>
            המערכת תאתר עבורכם את המשווק הקרוב אליכם עבור המוצר שחיפשתם
          </StyledPopupTextDescription>
          <StyledPopupNumberInputBox>
            <StyledPopupNumberInput
              ref={inputRef}
              placeholder={`הזינו כתובת ובחרו מהרשימה`}
              dir="rtl"
              spellCheck={false}
              disabled={!ready}
              value={value}
              // onFocus={() => {
              //   if (setAskLocation) {
              //     setAskLocation(true);
              //   }
              // }}
              onChange={onTextChanged}
              onKeyDown={detectArrows}
            ></StyledPopupNumberInput>
            <StyledPinWrapper>
              <PinIcon />
            </StyledPinWrapper>
            {status === "OK" && (
              <StyledOptionsPopup ref={popupRef}>
                {data.map(({ place_id, description }) => {
                  return (
                    <StyledOptionItem
                      key={place_id}
                      onClick={() => handleSelect(description)}
                      selected={selectedAddress === description}
                    >
                      {description}
                      <StyledIconWrapper>
                        <PinIcon />
                      </StyledIconWrapper>
                    </StyledOptionItem>
                  );
                })}
              </StyledOptionsPopup>
            )}
          </StyledPopupNumberInputBox>
          {/* <StyledPopupSubmitButton
            onClick={submitInput}
            disabled={value.length < 1}
          >
            <StyledButtonText>Submit address</StyledButtonText>
          </StyledPopupSubmitButton> */}
        </StyledSubmitNumberContent>
      </StyledSubmitNumberBox>
    </StyledSubmitNumberModal>
  );
};

export default AddressModal;
