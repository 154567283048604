import * as React from "react";
const CityIcon = (
    props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
  ) => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 0.281 0.281"
    xmlns="http://www.w3.org/2000/svg"
    id="city"
    {...props}
  >
    <path d="M0.256 0.075h-0.031V0.026a0.007 0.007 0 0 0 -0.007 -0.007h-0.005a0.007 0.007 0 0 0 -0.007 0.007V0.075H0.175a0.007 0.007 0 0 0 -0.007 0.007v0.106H0.101c-0.004 0 -0.007 0.003 -0.007 0.007v0.061c0 0.004 0.003 0.007 0.007 0.007h0.155c0.004 0 0.007 -0.003 0.007 -0.007V0.082a0.007 0.007 0 0 0 -0.007 -0.007zm-0.124 0.15h-0.019v-0.019h0.019v0.019zm0.037 0h-0.019v-0.019h0.019v0.019zm0.037 0h-0.019v-0.019h0.019v0.019zm0 -0.037h-0.019v-0.019h0.019v0.019zm0 -0.037h-0.019v-0.019h0.019v0.019zm0 -0.037h-0.019v-0.019h0.019v0.019zm0.037 0.112h-0.019v-0.019h0.019v0.019zm0 -0.037h-0.019v-0.019h0.019v0.019zm0 -0.037h-0.019v-0.019h0.019v0.019zm0 -0.037h-0.019v-0.019h0.019v0.019zm-0.094 -0.087A0.007 0.007 0 0 0 0.143 0.019H0.101a0.007 0.007 0 0 0 -0.007 0.007V0.056H0.063a0.007 0.007 0 0 0 -0.007 0.007V0.075H0.026a0.007 0.007 0 0 0 -0.007 0.007v0.174c0 0.004 0.003 0.007 0.007 0.007H0.075V0.169h0.075V0.026zM0.056 0.225H0.037v-0.019h0.019v0.019zm0 -0.037H0.037v-0.019h0.019v0.019zm0 -0.037H0.037v-0.019h0.019v0.019zm0 -0.037H0.037v-0.019h0.019v0.019zm0.037 0.037H0.075v-0.019h0.019v0.019zm0 -0.037H0.075v-0.019h0.019v0.019zm0.037 0.037H0.112v-0.019h0.019v0.019zm0 -0.037H0.112v-0.019h0.019v0.019zm0 -0.037H0.112V0.056h0.019v0.019z" />
  </svg>
);
export default CityIcon;
