import * as React from 'react';

const ArrowIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7.061}
    height={4.28}
    viewBox="0 0 7.061 4.28"
    {...props}
  >
    <path
      id="Line_145"
      data-name="Line 145"
      d="M6,3,3,0,0,3"
      transform="translate(6.53 3.53) rotate(180)"
      fill="none"
      stroke={props.fill || '#e81d2d'}
      strokeLinejoin="round"
      strokeWidth={1.5}
      style={{
        mixBlendMode: 'normal',
        isolation: 'isolate',
      }}
    />
  </svg>
);

export default ArrowIcon;
