import React from 'react';
import styled from 'styled-components';

import Loader from 'assets/imgs/Loader.png';

interface SizeProps {
  size: 'xs' | 'xl' | 'xxl' | 'extraLarge' | '';
}

const StyledSpinner = styled.div<SizeProps>`
  height: ${(p) => (p.size === 'xs' ? '25px' : '100%')};
  width: ${(p) => p.size === 'xxl' && '100%'};
  /* ${(p) => p.size === 'xs' && `margin-left: 40px;`} */
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    height: ${(p) => (p.size === 'xs' ? '25px' : '32px')};
    ${(p) => p.size === 'extraLarge' && `
      height: 100px;
    `}
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
`;

type Props = {
  size: 'xs' | 'xl' | 'xxl' | 'extraLarge' | '';
};

const LoaderSpinner: React.FC<Props> = ({ size }): JSX.Element => {
  return (
    <StyledSpinner size={size}>
      <img src={Loader} alt={'loader'}></img>
    </StyledSpinner>
  );
};

export default LoaderSpinner;
