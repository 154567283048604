import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import queryString from "query-string";

import axios from "axios";

import detectMobile from "utils/detectMobile";
import permutator from "utils/permutator";

import MapsContainer from "../MapsContainer";
import NearestStoreContainer from "../NearestStoreContainer";
import SubmitNumberModal from "../SubmitModal";
import AddressModal from "components/SubmitModal/AddressModal";
import { CategoryType, HebCategoryType, CategoryEnum } from "config";

const isMobileDevice = detectMobile();
const savedLocation = JSON.parse(
  localStorage.getItem("savedLocation") as string
);
const options = {
  enableHighAccuracy: true,
};
const getTranslatedCategory = (category: string) => {
  let match;
  if (!category) {
    return;
  }
  const permArr = permutator(category.split(" "));

  for (let i = 0; i < permArr.length; i++) {
    const name = permArr[i].join(" ");
    match = Object.entries(CategoryEnum).find(([key, val]) => key === name);
    if (match) break;
  }

  return !!match ? match[1] : undefined;
};

interface Props {
  storesData: any;
  setStoresData: React.Dispatch<any>;
  modalType: "phone" | "whatsapp" | "address" | null;
  setModalType: React.Dispatch<
    React.SetStateAction<"address" | "phone" | "whatsapp" | null>
  >;
}

const LoadedMainContent: React.FC<Props> = ({
  storesData,
  setStoresData,
  modalType,
  setModalType,
}) => {
  const location = useLocation();
  const parsed = useMemo(
    () => queryString.parse(location?.search),
    [location?.search]
  );
  const [productName, setProductName] = useState("אתר אינטרנט");
  const [selectedProductType, setSelectedProductType] = useState<CategoryType>(
    getTranslatedCategory((parsed?.category as string)?.trim()) ?? ""
  );
  // const [askLocation, setAskLocation] = useState(false);
  const [suggestSavedAddress, setSuggestSavedAddress] = useState(true);

  const [selectedStoreId, setSelectedStoreId] = useState<number | null>(null);
  const [markerFocused, setMarkerFocused] = useState<number | null>(null);
  const [userLocation, setUserLocation] = useState<{
    city: string;
    lat: number;
    lng: number;
  }>(
    savedLocation || {
      city: "",
      lat: 31.4117,
      lng: 35.0818,
    }
  );

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: "iw",
    },
    debounce: 300,
  });

  useEffect(() => {
    if (typeof parsed?.product === "string") {
      setProductName(parsed?.product.trim());
    } else {
      setProductName("אתר אינטרנט");
    }
    if (typeof parsed?.category === "string") {
      const category = getTranslatedCategory(parsed?.category.trim());
      if (typeof category === "string") {
        setSelectedProductType(category);
      }
    }
  }, [parsed]);

  const openPopup = (selectedItem: any, isWhatsApp?: boolean) => {
    setSelectedStoreId(selectedItem.id);
    setModalType(isWhatsApp ? "whatsapp" : "phone");
  };

  const closePopup = useCallback(() => {
    setSelectedStoreId(null);
    setModalType(null);
  }, [setModalType]);

  const fetchStoreData = useCallback(
    async (
      lattitude?: number,
      longitude?: number,
      productCategory?: CategoryType
    ) => {
      const lat = lattitude ?? userLocation.lat;
      const lng = longitude ?? userLocation.lng;
      const category = productCategory ?? selectedProductType;
      console.log(
        "debug lat lng: ",
        lat,
        lng
      );
      console.log(
        "debug productCategory ?? selectedProductType: ",
        productCategory,
        selectedProductType
      );
      setStoresData(null);
      closePopup();
      let res;

      if (!category) {
        res = await axios.get(`${process.env.REACT_APP_API_URL}store`, {
          params: { lat, lng, cityFilter: "true" },
        });
      } else {
        res = await axios.get(`${process.env.REACT_APP_API_URL}store`, {
          params: {
            lat,
            lng,
            category,
            cityFilter: "true",
          },
        });
      }

      if (res?.status === 200) {
        setStoresData(res.data);
      }
    },
    [closePopup, selectedProductType, setStoresData, userLocation]
  );

  const estimateCity = () => {
    const userLocation = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const estimatedCity = userLocation.substring(userLocation.indexOf("/") + 1);
    return estimatedCity;
  };

  const success = useCallback(
    (position: GeolocationPosition) => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      geocoder.geocode({ location: latlng }, (result, status) => {
        if (status === "OK") {
          const userCity = result?.[0]?.formatted_address;
          const locationObj = {
            city: userCity || estimateCity(),
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(locationObj);
          setValue(locationObj.city);
          fetchStoreData(locationObj.lat, locationObj.lng);
          localStorage.setItem("savedLocation", JSON.stringify(locationObj));
        }
      });
    },
    [fetchStoreData, setValue]
  );

  const error = (err: GeolocationPositionError) => {
    console.log(`GeolocationPositionError ERROR(${err.code}): ${err.message}`);

    // for test purpose only:
    
    // setTimeout(() => {
    //   const resp = {
    //     coords: {
    //       accuracy: 40,
    //       altitude: null,
    //       altitudeAccuracy: null,
    //       heading: null,
    //       latitude: 32.109333,
    //       longitude: 34.855499,
    //       speed: null,
    //     },
    //     timestamp: Date.now(),
    //   };

    //   const locationObj = {
    //     city: "בלוך 17, גבעתיים, ישראל",
    //     lat: resp.coords.latitude,
    //     lng: resp.coords.longitude,
    //   };
    //   setUserLocation(locationObj);
    //   setValue(locationObj.city);
    //   fetchStoreData(locationObj.lat, locationObj.lng);
    //   localStorage.setItem("savedLocation", JSON.stringify(locationObj));
    // }, 912);
  };

  const getGeoLocatio = useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      console.log("Error in geolocation in navigator");
    }
  }, [success]);

  // useEffect(() => {
  //   if (modalType !== "address" && !!selectedProductType) {
  //     fetchStoreData(undefined, undefined, selectedProductType);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchStoreData, selectedProductType]);

  ////////
  //////  address input logic
  ////////
  useEffect(() => {
    if (suggestSavedAddress) {
      setValue(userLocation.city);
      setSuggestSavedAddress(false);
    }
  }, [userLocation.city, suggestSavedAddress, setValue]);

  const handleSelect = useCallback(
    async (address: string) => {
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      localStorage.setItem(
        "savedLocation",
        JSON.stringify({ city: address, lat, lng })
      );
      setUserLocation({ city: address, lat, lng });

      fetchStoreData(lat, lng);
    },
    [clearSuggestions, setUserLocation, setValue, fetchStoreData]
  );

  return (
    <>
      {modalType === "address" && storesData === null && (
        <AddressModal
          getGeoLocatio={getGeoLocatio}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
          fetchStoreData={fetchStoreData}
          closePopup={closePopup}
          ready={ready}
          value={value}
          setValue={setValue}
          status={status}
          data={data}
          handleSelect={handleSelect}
        />
      )}
      {(modalType === "phone" || modalType === "whatsapp") && (
        <SubmitNumberModal
          modalType={modalType}
          selectedStoreId={selectedStoreId}
          productName={productName}
          closePopup={closePopup}
        />
      )}
      {!isMobileDevice && (
        <MapsContainer
          readyToShow={userLocation && storesData !== null}
          //   isLoaded={isLoaded}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
          markerFocused={markerFocused}
          setMarkerFocused={setMarkerFocused}
          setValue={setValue}
          storesData={storesData}
          fetchStoreData={fetchStoreData}
        />
      )}
      <NearestStoreContainer
        parsed={parsed}
        readyToShow={userLocation && storesData !== null}
        openPopup={openPopup}
        markerFocused={markerFocused}
        setMarkerFocused={setMarkerFocused}
        storesData={storesData}
        fetchStoreData={fetchStoreData}
        ready={ready}
        value={modalType === "address" ? "" : value}
        setValue={setValue}
        status={modalType === "address" ? "" : status}
        data={modalType === "address" ? [] : data}
        selectedProductType={
          typeof parsed?.category === "string"
            ? (parsed?.category.trim() as HebCategoryType)
            : ""
        }
        handleSelect={handleSelect}
      />
    </>
  );
};

export default LoadedMainContent;
