import React, { useState, useEffect } from "react";

import { useJsApiLoader } from "@react-google-maps/api";

import LoaderSpinner from "atoms/LoaderSpinner";

import {
  StyledMainContent,
  StyledLoaderWrapper,
  StyledCloseWindow,
  StyledTooltip,
} from "./styles";
import LoadedMainContent from "./LoadedMainContent";

const MainContent: React.FC = () => {
  const [storesData, setStoresData] = useState<any>(null);
  const [modalType, setModalType] = useState<
    "phone" | "whatsapp" | "address" | null
  >("address");
  const { isLoaded, loadError } = useJsApiLoader({
    // id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: ["places"],
    language: "iw",
  });

  useEffect(() => {
    if (loadError) console.log(loadError);
  }, [loadError]);

  return (
    <StyledMainContent>
      {modalType === null && storesData === null && (
        <StyledLoaderWrapper>
          <LoaderSpinner size={"extraLarge"} />
        </StyledLoaderWrapper>
      )}
      <StyledCloseWindow onClick={() => window.history.back ? window.history.back() : window.location.href = 'https://www.argentools.co.il/'}>
        X<StyledTooltip className="tooltiptext">חזרה</StyledTooltip>
      </StyledCloseWindow>
      {isLoaded && (
        <LoadedMainContent
          storesData={storesData}
          setStoresData={setStoresData}
          modalType={modalType}
          setModalType={setModalType}
        />
      )}
    </StyledMainContent>
  );
};

export default MainContent;
