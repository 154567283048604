import React, { useMemo, memo, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { SetValue } from "use-places-autocomplete";

import { CategoryType } from "config";

import LoaderSpinner from "atoms/LoaderSpinner";
import Logo from "assets/imgs/Logo.png";
import LogoTransparent from "assets/imgs/LogoTransparent.png";
import userMarker from "assets/imgs/userMarkerV2.png";
import CloseIcon from "assets/imgs/CloseIcon.png";
import CloseHoverIcon from "assets/imgs/CloseHoverIcon.png";

import {
  StyledMapsContainer,
  StyledHintBox,
  StyledHintClose,
  StyledHintText,
  StyledHintImgWrapper,
  StyledHintImg,
} from "./styles";

interface Props {
  readyToShow: boolean;
  userLocation: {
    city: string;
    lat: number;
    lng: number;
  };
  setUserLocation: React.Dispatch<
    React.SetStateAction<{
      city: string;
      lat: number;
      lng: number;
    }>
  >;
  markerFocused: number | null;
  setMarkerFocused: React.Dispatch<React.SetStateAction<number | null>>;
  setValue: SetValue;
  storesData: any;
  fetchStoreData: (
    lattitude?: number,
    longitude?: number,
    productCategory?: CategoryType
  ) => Promise<void>;
}

const containerStyle = {
  border: "0",
  width: "100%",
  height: `100vh`,
  // height: `calc(100vh + 70px)`,
  // marginTop: "-70px",
};

// const position = JSON.parse(localStorage.getItem("savedLocation") as string);

const MapsContainer: React.FC<Props> = ({
  readyToShow,
  userLocation,
  setUserLocation,
  markerFocused,
  setMarkerFocused,
  setValue,
  storesData,
  fetchStoreData,
}) => {
  const [map, setMap] = useState<any>(null);
  const [hoveredMarker, setHoveredMarker] = useState<number | null>(null);
  const [showHintBox, setShowHintBox] = useState(true);
  const center = useMemo(
    () => ({
      lat: userLocation.lat,
      lng: userLocation.lng,
    }),
    [userLocation]
  );

  const handleShowHintBox = () => {
    setShowHintBox(false);
  };

  const handleCenterUser = () => {
    map.setCenter(center);
  };

  const handleDrag = (e: google.maps.MapMouseEvent) => {
    const geocoder = new google.maps.Geocoder();

    if (e.latLng?.lat() && e.latLng?.lng()) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      const latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode({ language: 'iw', location: latlng }, (result, status) => {
        if (status === "OK") {
          const userCity = result?.[0]?.formatted_address;
          const locationObj = {
            city: userCity || "",
            lat,
            lng,
          };
          setUserLocation({
            city: userLocation.city,
            lat,
            lng,
          });
          setValue(locationObj.city);
          fetchStoreData(lat, lng);

          localStorage.setItem("savedLocation", JSON.stringify(locationObj));
        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  };

  return (
    <StyledMapsContainer>
      {showHintBox && (
        <StyledHintBox>
          <StyledHintText>
            <StyledHintClose
              onClick={handleShowHintBox}
              bgImg={CloseIcon}
              bgImgHover={CloseHoverIcon}
            />
            בכדי לשנות את מיקומך
            <StyledHintImgWrapper onClick={handleCenterUser}>
              <StyledHintImg src={userMarker} alt="markerImg" />
            </StyledHintImgWrapper>
            הזז את הבועית{" "}
          </StyledHintText>
        </StyledHintBox>
      )}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          disableDoubleClickZoom: true,
        }}
        onLoad={(map) => setMap(map)}
        onUnmount={() => setMap(null)}
      >
        <Marker
          position={center}
          icon={{
            url: userMarker,
            size: new google.maps.Size(20, 20),
            scaledSize: new google.maps.Size(20, 20),
            // origin: new google.maps.Point(10, 10),
            anchor: new google.maps.Point(10, 10),
          }}
          // animation={google.maps.Animation.DROP}
          cursor="grab"
          zIndex={1001}
          draggable
          onDragEnd={handleDrag}
          // onClick={() => alert("You are here")}
        />
        {readyToShow &&
          storesData?.length > 0 &&
          storesData.map((item: any) => {
            if (!item?.id || !item?.lat || !item?.lng) {
              // eslint-disable-next-line array-callback-return
              return;
            }
            if (markerFocused === item.id) {
              map.setCenter({
                lat: item.lat,
                lng: item.lng,
              });
            }
            return (
              <Marker
                key={item.id}
                position={{
                  lat: item.lat,
                  lng: item.lng,
                }}
                icon={{
                  url: markerFocused === item.id ? Logo : LogoTransparent,
                  size: new google.maps.Size(
                    markerFocused === item.id ? 84.85 : 42.42,
                    markerFocused === item.id ? 60 : 30
                  ),
                  scaledSize: new google.maps.Size(
                    markerFocused === item.id ? 84.85 : 42.42,
                    markerFocused === item.id ? 60 : 30
                  ),
                  // origin: new google.maps.Point(10, 10),
                  // anchor: new google.maps.Point(markerFocused === item.id ? 84.85 : 42.42, markerFocused === item.id ? 60 : 30),
                }}
                animation={
                  markerFocused === item.id
                    ? google.maps.Animation.BOUNCE
                    : undefined
                }
                zIndex={markerFocused === item.id ? 1000 : undefined}
                opacity={
                  markerFocused !== null && markerFocused !== item.id ? 0.7 : 1
                }
                onClick={() =>
                  setMarkerFocused((prev) =>
                    prev === item.id ? null : item.id
                  )
                }
                // onMouseOver={() => setHoveredMarker(item.id)}
                // onMouseOut={() => setHoveredMarker(null)}
              />
            );
          })}
      </GoogleMap>
    </StyledMapsContainer>
  );
};

export default memo(MapsContainer);
