import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import MainContent from "./components/MainContent";

const App = () => {
  return (
    <Routes>
        <Route path="/*" element={<MainContent />} />
    </Routes>
  );
}

export default App;
